import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./appendix.module.scss"


class AppendixTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const videoRoot = this.props.data.site.siteMetadata.videoRoot
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    const lang = post.fields.slug.split('/')[1]
    const { altPost, parent, children } = this.props.pageContext
    console.log(`children.${children.length}`)
    const siteTitle = translate('site.name', lang)
    const headerSubTitle = parent ? parent.frontmatter.title : translate('site.description', lang)
    const background = post.frontmatter.background ? `url(${imageRoot}/${post.frontmatter.background})` : `none`
    const hasBackGround = post.frontmatter.background ? true : false
    const images = hasBackGround ? [post.frontmatter.background] : []

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} isReverse={true} world={hasBackGround}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          images={images}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={`sub-content overview about ${hasBackGround ? 'has-background' : ''}`}
            style={{
              backgroundImage: background,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          >
            <div>
              <h1>{post.frontmatter.title}</h1>
              <hr></hr>
              {
                altPost && (
                  <h2>{altPost.frontmatter.title}</h2>
                )
              }
            </div>
            {
              parent && (
                <div className="footer">
                  <Link rel="back"
                    to={parent.fields.slug}
                    style={{
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`,
                    }}>
                    <button>Back</button>
                  </Link>
                </div>
              )
            }
          </div>
          <div className="sub-content detail">
            <div className="inner">
              <div dangerouslySetInnerHTML={{ __html: post.html }} ></div>
              {
                children.map(
                  (child, i) => {
                    return <h3 key={i} className={styles.child}>
                      <Link to={child.fields.slug}
                        style={{
                          boxShadow: `none`,
                          textDecoration: `none`,
                          color: `inherit`,
                        }}
                      >{child.frontmatter.title}</Link>
                    </h3>
                  }
                )
              }
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default AppendixTemplate

export const pageQuery = graphql`
  query AppendixBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        videoRoot
        imageRoot
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        videos
        videoTitles
        videoDescriptions
        videoCaptions
        background
      }
    }
  }
`
